import { makeStyles } from '@mui/styles';
const styles = makeStyles(() => ({
  dialogModal: {
    '& .MuiPaper-root': {
      marginLeft: '10px',
      width: '650px !important',
      '& > div:first-child': {
        padding: '4px 10px !important',
      },
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '1.3rem',
      fontWeight: 'bold',
    }
  },
  searchBar: {
    backgroundColor: 'transparent',
    width: '25vh',
    '&.MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
    },
    '&.MuiInputBase-root :hover': {
      backgroundColor: 'transparent',
    },
    fontSize: 'larger',
  },
}));
export default styles;
