import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datagrid from '../Datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import columns from './table_config';
import {
  listSellers,
  assignCompanySellers,
  assignUserSellers,
} from '../../../store/actions/sellers';
import { useAuth } from '../../../hooks/useAuth';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import { Grid, IconButton } from '@mui/material';
import _ from 'lodash';
import { CancelPresentationRounded } from '@mui/icons-material';

const SellersModalDialog = ({
  isOpen,
  title,
  selectedCompany,
  onCloseClick,
  selectedUser
}) => {
  const companies = useSelector((state) => state.companies);
  const sellers = useSelector((state) => state.sellers);
  const apiRef = useGridApiRef();

  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sellers.loading && sellers.data.length === 0) {
      dispatch(listSellers(user));
    }
  }, []);

  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    if (selectedCompany || selectedUser) {
      const sellersIds = selectedCompany?.sellers || selectedUser?.sellers;
      const listOfSellers = _.intersectionWith(
        companies.data,
        sellersIds,
        (company, id) => company._id === id
      );

      const sellerIds = [];
      for (let index = 0; index < listOfSellers.length; index++) {
        const sellerId = listOfSellers[index]._id;
        sellerIds.push(sellerId);
      }
      setSelectionModel(sellerIds);
    }
  }, [selectedCompany, selectedUser]);

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {'Sellers'}
          </Grid>
          <Grid item>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const onDialogCloseClick = () => {
    onCloseClick();
  };

  const createDialogButtons = () => [
    {
      text: 'Ok',
      placeholder: 'Ok',
      'aria-label': 'ok',
      onClick: () => {
        const sellers = [];
        for (const seller of apiRef.current.getSelectedRows().values()) {
          sellers.push(seller._id);
        }
        if (selectedCompany?._id) {
          dispatch(assignCompanySellers(selectedCompany?._id, sellers));
        } else {
          dispatch(assignUserSellers(selectedUser?._id, sellers));
        }
        onCloseClick();
      },
    },
  ];

  if (apiRef.current === null) apiRef.current = {};

  return (
    <ModalDialog
      title={title || getModalTitle()}
      isOpen={isOpen}
      onClose={onDialogCloseClick}
      buttons={createDialogButtons()}
    >
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Datagrid
            data={sellers.data}
            autoHeight={false}
            columns={columns}
            apiRef={apiRef}
            checkboxSelection
            getRowId={(row) => row._id}
            editMode="cell"
            disableSelectionOnClick
            disableStickyHeader={false}
            pageSize={100}
            height={'400px'}
            selectionModel={selectionModel}
            onSelectionModelChange={(e) => {
              setSelectionModel(e);
            }}
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
};

SellersModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  selectedUser: PropTypes.object
};

export default SellersModalDialog;
