import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_ACTIONS } from '../../../shared/config/constants';

const SelectJobRigAutoComplete = (props) => {
  const { id, field, data, setSelectedRig } = props;
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();
  const classes = styles();
  const [selectedValue, setSelectedValue] = React.useState(null);
  const jobs = useSelector((state) => state.jobs);

  const updateData = (id, field, value) => {
    setSelectedRig(value.rig);
    const updatedProperties = jobs.data.map(item => {
      if (item._id === id) {
        return {
          ...item,
          Rig: value.rig,
          Well: '',
          wellsInfoId: ''
        };
      }
      return item;
    });
    dispatch({
      type: REDUX_ACTIONS.JOBS_LIST_SUCCESS,
      payload: {
        data: [...updatedProperties],
        paginatedElements: updatedProperties.length,
      },
    });
  };

  React.useEffect(() => {
    const initialValue = data.find((option) => option._id === id) || null;
    setSelectedValue(initialValue);
  }, [id, data]);

  const handleChange = async (event, newValue) => {
    event.stopPropagation();
    event.preventDefault();
    updateData(id, field, newValue);
    setSelectedValue(newValue);
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue?.rig,
    });
    if (props?.closeCellAfterSelect) {
      apiRef.current.setCellMode(id, field, 'view');
    }
  };
  return (
    <FormControl
      variant="filled"
      id="controlled-form"
      className={classes.inputField}
      sx={{ bgcolor: 'transparent' }}
    >
      <Autocomplete
        options={data?.sort((a, b) => a.rig.localeCompare(b.rig))}
        value={data.find(option => option._id === selectedValue?._id) || null}
        getOptionLabel={(option) => {
          return option?.rig;
        }}
        id="controlled-demo"
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <TextField {...params} label="Select" variant="standard" InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }} />
        )}
      />
    </FormControl>
  );
};

SelectJobRigAutoComplete.propTypes = {
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  value: PropTypes.any,
  data: PropTypes.any,
  closeCellAfterSelect: PropTypes.any,
  setSelectedRig: PropTypes.any
};

export default SelectJobRigAutoComplete;
