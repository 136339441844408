import { useState, useEffect } from 'react';
import UnSavedChangesConfirmationModal from '../components/unsavedChanges';

const useUnsavedChanges = (properties, onCloseClick) => {
  const [unSavedExists, setUnSavedExists] = useState(false);
  const [unSavedAction, setUnSavedAction] = useState(false);

  useEffect(() => {
    const data = properties.filter((ele) => Object.keys(ele).includes('isDirty') || ele?.isDirty);
    setUnSavedExists(!!data.length);
  }, [properties]);

  const handleClose = (action) => {
    if (action) {
      setUnSavedAction(false);
    } else {
      setUnSavedAction(true);
    }
  };

  const UnsavedChangesModal = () => (
    unSavedAction && (
      <UnSavedChangesConfirmationModal
        open={unSavedAction}
        handleClose={() => setUnSavedAction(false)}
        handleMainDialogClose={onCloseClick}
      />
    )
  );

  return {
    unSavedExists,
    handleClose,
    UnsavedChangesModal
  };
};

export default useUnsavedChanges;
