import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const getFavoriteParts = (query) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.FAVORITE_PARTS_DATA_LOADING,
  });
  const body = {
    query
  };
  return fetch
    .post('users/fetch/favoriteParts', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.FAVORITE_PARTS_DATA_SUCCESS,
        payload: {
          data: data.data,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.FAVORITE_PARTS_DATA_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};
