// Create an array of arrays
// Split by ","
export const fromGridRowsToObjectArray = (dataString) => {
  const lines = dataString
    .split(/\r\n/) // Convert to one string per line
    .map(function (lineStr) {
      return lineStr.split(','); // Convert each line to array (,)
    }); // .slice(1); // Discard header line

  const jsonObj = [];
  const headers = lines[0];
  for (let i = 1; i < lines.length; i++) {
    const _data = lines[i];
    const obj = {};
    for (let j = 0; j < _data.length; j++) {
      obj[headers[j]?.trim()] = _data[j]?.trim();
    }
    jsonObj.push(obj);
  }
  return jsonObj;
};
