import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { CancelPresentationRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import styles from './styles';
import { getFavoriteParts } from '../../../store/actions/favoriteParts';
import Datagrid from '../Datagrid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { USER_TYPE } from '../../../utils/constants';
import columns from './table_config';
import DescriptionImageModalDialog from '../DescriptionImageModalDialog';
import { renderCell } from '../Datagrid/table_config';
import { useAuth } from '../../../hooks/useAuth';

const FavoritePartDialog = ({ open, handleClose, rowData }) => {
  const favoriteParts = useSelector((state) => state.favoritePart);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const apiRef = useGridApiRef();
  const classes = styles();
  const [partImage, setPartImage] = useState({
    open: false,
    data: {}
  });

  useEffect(() => {
    const query = {
      userId: rowData?._id,
      inputId: rowData?.type === USER_TYPE.CUSTOMER_USER ? rowData?.companyId : rowData?._id
    };
    dispatch(getFavoriteParts(query));
  }, []);

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {'Favorites'}
          </Grid>
          <Grid item>
          <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderDescriptionCell = ({ value, row }) => (
    <Typography
      onClick={() => setPartImage({
        open: true, data: row
      })}
      style={{ fontSize: '14px', textWrap: 'wrap', cursor: 'pointer' }}
    >{value}
    </Typography>
  );

  return (
    <React.Fragment>
      <ModalDialog
        title={getModalTitle()}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        dialogClass={classes.dialogModal}
        isOpen={open}
        fullWidth
        maxWidth='lg'
      >
         <Datagrid
            apiRef={apiRef}
            data={favoriteParts?.data}
            columns={columns(renderCell, user.type, renderDescriptionCell)}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
            loading={favoriteParts.loading}
            onRowClick={(params, evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            disableStickyHeader={false}
            autoHeight={false}
            autoheight
            height={'50vh'}
            pageSize={100}
            toolbar={{
              options: {
                columns: false,
                filters: true,
                density: false,
                export: false,
              },
            }}
          />
           {partImage.open &&
          <DescriptionImageModalDialog
          data={partImage.data}
          image={partImage.data.Image}
          isOpen={partImage.open}
          onClose={() => setPartImage({
            open: false,
            data: {}
          })}
          />
        }
      </ModalDialog>
    </React.Fragment>
  );
};

FavoritePartDialog.propTypes = {
  open: PropTypes.bool,
  rowData: PropTypes.object,
  handleClose: PropTypes.func,
};

export default FavoritePartDialog;
