import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';

export const getUnassignedAssets = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ASSETS_LOADING,
  });

  return fetch
    .post('assets/assets')
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.ASSETS_SUCCESS,
        payload: {
          data: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );

      dispatch({
        type: REDUX_ACTIONS.ASSETS_DATA_ERROR,
        payload: { ...error, message: 'Error' },
      });
    });
};

export const createMpdAsset = (body) => (dispatch) => {
  delete body.isNewRow;
  delete body._id;
  const input = {
    input: {
      ...body,
    }
  };
  return fetch
    .post('mpdAssets/create', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Asset Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(getUnassignedAssets());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(getUnassignedAssets());
    });
};
export const updateMpdAsset = (body) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ASSETS_LOADING,
  });
  const input = {
    query: {
      _id: body?._id
    },
    input: {
      SN: body.SN,
      Type: body.Type,
      jobId: body.jobId,
    }
  };
  return fetch
    .put('mpdAssets', input)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Asset Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(getUnassignedAssets());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(getUnassignedAssets());
    });
};
export const deleteMpdAsset = (_id) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.ASSETS_LOADING,
  });
  const body = {
    query: {
      _id,
    }
  };
  return fetch
    .deleteAction('mpdAssets', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Asset Deleted successfully',
          'success',
          new Date().getTime() + Math.random()
        ));
      dispatch(getUnassignedAssets());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        ));
      dispatch(getUnassignedAssets());
    });
};
