import { REDUX_ACTIONS } from '../../shared/config/constants';

const initialState = {
  loading: false,
  deleting: false,
  data: [],
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  currentJob: {
    assets: [],
  },
  searchTerm: '',
  initialData: [],
  marker_data: [],
  markerFilter: false,
  customReportData: [],
  sslGenerate: 'idle',
};

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.JOBS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REDUX_ACTIONS.JOB_ASSETS_LIST_LOADING:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          loading: true,
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.JOB_ASSETS_SAVING:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          loading: true,
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.JOBS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        initialData: [...action.payload.data],
        paginatedElements: action.payload.paginatedElements,
        errorMessage: '',
        marker_data: [...action.payload.data],
      };
    case REDUX_ACTIONS.JOBS_LIST_SINGLE_ITEM_SUCCESS:
    {
      const data = [
        ...state.data.filter((item) => item._id === action.payload.data._id),
        ...action.payload.data
      ];
      return {
        ...state,
        loading: false,
        data,
        errorMessage: '',
      };
    }
    case REDUX_ACTIONS.JOB_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          loading: false,
          assets: [...action.payload.data],
          paginatedElements: action.payload.paginatedElements,
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.SET_CURRENT_JOB:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          _id: action.payload.jobId,
        },
      };
    case REDUX_ACTIONS.JOB_SIGNALS_LIST_SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          signals: action.payload.data,
        },
      };
    case REDUX_ACTIONS.JOB_ASSETS_SAVE_SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          loading: false,
          assets: [...state.currentJob.assets, ...action.payload.data],
          paginatedElements: action.payload.paginatedElements,
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.JOB_ASSETS_REMOVE_SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          loading: false,
          assets: [
            ...state.currentJob.assets.filter(
              (asset) => !action.payload.data.includes(asset._id)
            ),
          ],
          paginatedElements: [
            ...state.currentJob.assets,
            ...action.payload.data,
          ].length,
          errorMessage: '',
        },
      };
    case REDUX_ACTIONS.JOBS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
        marker_data: []
      };
    case REDUX_ACTIONS.JOB_SIGNALS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.JOB_SIGNALS_DATA_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: [],
      };
    case REDUX_ACTIONS.JOB_ASSETS_LIST_ERROR:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          loading: false,
          errorMessage: action.payload.message,
          data: [],
        },
      };
    case REDUX_ACTIONS.JOB_SIGNALS_DATA_LIST_SUCCESS:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          actuatorDrillingSignalData: action.payload.data,
        },
      };
    case REDUX_ACTIONS.JOBS_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: [],
      };
    case REDUX_ACTIONS.JOBS_SEARCH_CHANGE:
      return {
        ...state,
        searchTerm: action.payload.value,
      };
    case REDUX_ACTIONS.JOBS_FILTER:
      return {
        ...state,
        data: [
          ...state.initialData.filter((item) => {
            const searchTerm = state.searchTerm.toUpperCase();
            return (
              item.Rig.toUpperCase().includes(searchTerm) ||
                item.Well.toUpperCase().includes(searchTerm)
            );
          }),
        ],
        marker_data: [
          ...state.initialData.filter((item) => {
            const searchTerm = state.searchTerm.toUpperCase();
            return (
              item.Rig.toUpperCase().includes(searchTerm) ||
                item.Well.toUpperCase().includes(searchTerm)
            );
          }),
        ],
        markerFilter: false
      };
    case REDUX_ACTIONS.JOBS_MARKER_FILTER:
      return {
        ...state,
        marker_data: [
          ...action.payload.data
        ],
        markerFilter: true
      };
    case REDUX_ACTIONS.JOBS_CUSTOM_REPORT_LIST_SUCCESS:
      return {
        ...state,
        customReportData: [...action.payload.data]
      };
    case REDUX_ACTIONS.SSL_GENERATE_LOADING:
      return {
        ...state,
        sslGenerate: 'loading'
      };
    case REDUX_ACTIONS.SSL_GENERATE_IDLE:
      return {
        ...state,
        sslGenerate: 'idle'
      };
    case REDUX_ACTIONS.SSL_GENERATE_ERROR:
      return {
        ...state,
        sslGenerate: 'error'
      };
    case REDUX_ACTIONS.JOBS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default jobsReducer;
