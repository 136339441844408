const columns = (renderCell) => [
  {
    field: 'hole_depth',
    headerName: 'Hole Depth (ft)',
    width: 240,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'bit_depth_offset',
    headerName: 'Bit Off-Bottom Offset (ft)',
    width: 310,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(1);
    },
  },
  {
    field: 'rop',
    headerName: 'ROP (ft/hr)',
    width: 230,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'flow_in',
    headerName: 'Flow Rate (gpm)',
    width: 235,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'sbp',
    headerName: 'SBP (psi)',
    width: 215,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'drillStringsId',
    headerName: 'Drillstrings',
    width: 225,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'drillFluidsId',
    headerName: 'Drilling Fluids',
    width: 225,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'trip_data',
    headerName: 'Stripping',
    width: 220,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  }
];
export default columns;
