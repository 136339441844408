import React, { useEffect, useRef, useState } from 'react';
import { Box, CardMedia, Grid, IconButton, Typography, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import styles from './styles';
import SyncMobileDevicesModal from '../SyncMobileDevices/SyncMobileDevicesModal';
import { CancelPresentationRounded, FileOpen, SaveRounded, AddModerator } from '@mui/icons-material';
import { deletePreferences, refreshEpicorToken, uploadProfilePicture } from '../../../../store/actions/authorization';
import { isMobile } from '../../../../utils/isMobile';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ButtonComponent from '../../../Button';
import { USER_TYPE } from '../../../../utils/constants';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';

const usersWithResetAccess = [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN];

const MBSIZE = 1048576;
export default function UserName() {
  const currentUser = useSelector((state) => state.currentUser);
  const classes = styles();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [isRefreshDialogOpen, setIsRefreshDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(currentUser.profilePic || null);
  const dropAreaRef = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const mobile = isMobile();

  useEffect(() => {
    if (currentUser.profilePicUpload) {
      setUploadOpen(false);
    }
  }, [currentUser]);

  const handleOpenUploadModal = () => {
    setUploadOpen(true);
    setUploadedImageUrl(currentUser.profilePic);
  };

  const handleCloseUpdateModal = () => {
    setUploadOpen(false);
    setSelectedFile(null);
    setUploadedImageUrl(null);
    setError(null);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= MBSIZE) {
      setSelectedFile(file);
      setUploadedImageUrl(URL.createObjectURL(file));
      setError(null);
    } else {
      setSelectedFile(null);
      if (file && (file.type !== 'image/png' && file.type !== 'image/jpeg')) {
        setError('Only PNG and JPEG files are allowed');
      } else if (file && file.size > MBSIZE) {
        setError('File size should be less than 1 MB');
      }
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];

    if (file && (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= MBSIZE) {
      setSelectedFile(file);
      setUploadedImageUrl(URL.createObjectURL(file));
      setError(null);
    } else {
      setSelectedFile(null);
      if (file && (file.type !== 'image/png' && file.type !== 'image/jpeg')) {
        setError('Only PNG and JPEG files are allowed');
      } else if (file && file.size > MBSIZE) {
        setError('File size should be less than 1 MB');
      }
    }
    e.dataTransfer.clearData();
  };
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadProfilePicture = async () => {
    dispatch(uploadProfilePicture(selectedFile));
    setSelectedFile(null);
  };

  const handleResetCloseModal = () => {
    setResetOpen(false);
  };

  const handleResetPreferences = () => {
    dispatch(deletePreferences(() => setResetOpen(false)));
  };

  const handleRefreshCloseModal = () => {
    setIsRefreshDialogOpen(false);
    dispatch({
      type: REDUX_ACTIONS.EPICOR_TOKEN_REFRESH_ERROR,
      payload: false
    });
  };

  const handleRefreshToken = () => {
    dispatch(refreshEpicorToken(() => setIsRefreshDialogOpen(false)));
  };

  return <>
    <span className={classes.bar}>|</span>
    <Typography
      color="ButtonText"
      className={classes.textLimit}
    >
       {currentUser.firstName}
    </Typography>
    <IconButton onClick={() => {
      handleOpenUploadModal();
    }}>
      { currentUser.profilePic
        ? <img src={currentUser.profilePic} style={{ width: '2em', height: '2em', borderRadius: '100%', objectFit: 'cover', }} />
        : <AccountCircleRoundedIcon fontSize='large' /> }
    </IconButton>
    <SyncMobileDevicesModal
        open={uploadOpen}
        onClose={handleCloseUpdateModal}
        handleClose={handleCloseUpdateModal}
        cta2Label={'Upload'}
        cta2Disabled={!selectedFile}
        cta2Action={handleUploadProfilePicture}
        showDateTimeRange={false}
        mobile={mobile}
      >
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Box>
        <IconButton
          onClick={() => {
            setResetOpen(true);
          }}
        >
          <RestartAltIcon />
        </IconButton>
       {usersWithResetAccess.includes(currentUser.type) &&
       <IconButton
          onClick={() => {
            setIsRefreshDialogOpen(true);
          }}
        >
          <AddModerator />
        </IconButton>}
      </Box>
        </Grid>
        <Box className={classes.logoContainer}>
          {!currentUser?.profilePic && !uploadedImageUrl
            ? <AccountCircleRoundedIcon style={{ fontSize: '200px' }} />
            : <CardMedia
            component="img"
            image={uploadedImageUrl}
            className={mobile ? classes.mobileLogo : classes.logo}
          /> }
        </Box>
        <Box style={{ marginTop: '2%' }}>
          <Typography variant="h4" gutterBottom component="div" style={{ textAlign: 'center' }}>
            Add or select media
          </Typography>
        </Box>
        <Box
          className={mobile ? (isDragging ? classes.dragDropMobile : classes.dropAreaMobile) : (isDragging ? classes.dragDrop : classes.dropArea)}
          ref={dropAreaRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="fileInput"
            ref={fileInputRef}
          />
          <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
            Drop files here to upload them or press button
            <br />
            <br />
            <IconButton
              edge="end"
              sx={{ marginRight: '1%' }}
              onClick={handleIconClick}
            >
              <FileOpen />
           </IconButton>
          </label>
        </Box>
        {error && <Box className={classes.errorMessage}>{error}</Box>}
        {selectedFile && <Box>Selected file: {selectedFile.name}</Box>}
        <p>
          Allowed file upload: <strong>one file only</strong>
          <br />
          File must be less than <strong>1 MB</strong>
          <br />
          Allowed file types: <strong>PNG / JPG</strong>
        </p>
        <Grid container justifyContent={'end'} alignItems={'center'}>
          <IconButton onClick={() => {
            handleUploadProfilePicture();
          }}
          disabled={!selectedFile}
          >
            <SaveRounded fontSize={mobile ? 'medium' : 'large'}/>
          </IconButton>
        </Grid>
      </SyncMobileDevicesModal>
      { resetOpen
        ? <Dialog
          PaperProps={{
            style: {
              width: '500px',
              height: '150px',
            },
          }}
        open={resetOpen}
        onClose={handleResetCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText color={'black'} fontSize={'18px'} fontWeight='bold' id="alert-dialog-description">
            Are you sure you want to clear all preferences ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonComponent label='No' onClick={handleResetCloseModal} />
          <ButtonComponent label='Yes' onClick={() => handleResetPreferences()} />
        </DialogActions>
      </Dialog>
        : null
      }

{ isRefreshDialogOpen
  ? <Dialog
          PaperProps={{
            style: {
              width: '500px',
              height: '160px',
            },
          }}
        open={isRefreshDialogOpen}
        onClose={handleRefreshCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
          <Box display={'flex'} alignItems="center" justifyContent="end">
          <IconButton
              onClick={handleRefreshCloseModal}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText style={{ marginTop: '-23px' }} color={'black'} fontSize={'18px'} fontWeight='bold' id="alert-dialog-description">
           {!currentUser.fetchEpicorToken ? 'Connect to Epicor?' : 'Failed to connect to Epicor?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {currentUser.fetchEpicorToken
            ? <ButtonComponent label='Close' onClick={handleRefreshCloseModal} />
            : <>
            <ButtonComponent label='Yes' onClick={() => handleRefreshToken()} /></>}
        </DialogActions>
      </Dialog>
  : null
      }
  </>;
}
