import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing }) => ({
  subtitleContainer: {
    paddingTop: spacing(1),
    paddingLeft: spacing(1),
  },
  disableIcon: {
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.26)'
  },
  invalidRow: {
    backgroundColor: '#ffcccc !important'
  }
}));

export default styles;
