const masterColumn = () => [
  {
    field: 'BinNum',
    headerName: 'Bin Number',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'CustNumName',
    headerName: 'Customer Name',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'Address1',
    headerName: 'Address',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'UserEmail',
    headerName: 'User Email',
    width: 240,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'division',
    headerName: 'Division',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
    sortable: false
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'lastUpdate',
    headerName: 'Date',
    width: 210,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'lat',
    headerName: 'Latitude',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'long',
    headerName: 'Longitude',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
];

export { masterColumn };
