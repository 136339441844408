/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { fetchCompanyUsers } from '../../../store/actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { CancelPresentationRounded } from '@mui/icons-material';
import AutocompleteComponent from '../Autocomplete';
import { Typography } from '@mui/material';
import { isMobile } from '../../../utils/isMobile';

export default function PersonnelDialog({ open, handleClose, rowData, handleSave }) {
  const [orderApprovers, setOrderApprovers] = useState([]);
  const [quoteApprovers, setQuoteApprovers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const dispatch = useDispatch();
  const companyUsers = useSelector((state) => state.users.companyUsers);

  useEffect(() => {
    setOrderApprovers(rowData?.orderApprovers || []);
    setQuoteApprovers(rowData?.quoteApprovers || []);
    setAdmins(rowData?.admin || []);
  }, [rowData]);

  useEffect(() => {
    dispatch(fetchCompanyUsers(rowData?.companyId || rowData?._id));
  }, []);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth='md'
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: isMobile() && '15px' }}>{rowData.name || rowData.firstName}</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
          })}
        >
     <CancelPresentationRounded fontSize='large'/>
        </IconButton>
        <DialogContent dividers sx={{ minWidth: '600px' }}>
            <AutocompleteComponent
                title='Order Approvers'
                data={orderApprovers}
                onChange={(event, values) => setOrderApprovers([...values])}
                options={companyUsers}
            />
            <AutocompleteComponent
                title='Quote Approvers'
                data={quoteApprovers}
                onChange={(event, values) => setQuoteApprovers([...values])}
                options={companyUsers}
            />
            <AutocompleteComponent
                title='Admins'
                data={admins}
                onChange={(event, values) => setAdmins([...values])}
                options={companyUsers}
            />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            const updatedData = { ...rowData };
            updatedData.orderApprovers = orderApprovers;
            updatedData.quoteApprovers = quoteApprovers;
            updatedData.admin = admins;
            handleSave(updatedData);
            handleClose();
          }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
