import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import proGeoBlueIcon from '../../assets/images/pro_geo_blue.png';
import pinRed from '../../assets/images/pin_red.png';
import Datagrid from '../ui/Datagrid';
import { masterColumn } from './table_config';
import { Grid, TablePagination } from '@mui/material';
import PropTypes from 'prop-types';
import { MAP_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import { Icon } from 'leaflet';
import { exportCheckIns, listCheckIns } from '../../store/actions/checkins';
import Map from '../map';
import { listRigs } from '../../store/actions/rigs';
import styles from './styles';

const pins = {
  checkInsIcon: proGeoBlueIcon,
  rigIcon: pinRed
};

// eslint-disable-next-line react/prop-types
function CustomPagination({ currentPage, limit, totalCount, handlePagination, loading }) {
  return (
    <TablePagination
      component="div"
      count={totalCount}
      page={currentPage}
      onPageChange={(event, page) => !loading && handlePagination(page)}
      rowsPerPage={limit}
      rowsPerPageOptions={[]}
    />
  );
}

const CheckIns = () => {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const checkIns = useSelector((state) => state.checkIns);
  const rigs = useSelector((state) => state.rigs);
  const user = useSelector((state) => state.currentUser);
  const mapRef = useRef(null);
  const [filter, setFilter] = useState([]);
  const mapType = process.env.REACT_APP_CHECKINS_PAGE_MAP;
  const [rowId, setRowId] = useState(null);
  const [gmapRowId, setGmapRowId] = useState(null);
  const [iconPinSize, setIconPinSize] = useState([15, 25]);
  const classes = styles();

  useEffect(() => {
    // this effect is used to update the map size when the user changes viewport to widescreen
    if (!mapRef?.current?.invalidateSize) return;
    mapRef.current.invalidateSize();
  }, [user.preferences.aspectRatioPage]);

  useEffect(() => {
    if (filter.length === 0 && checkIns.data.length) {
      dispatch(listCheckIns());
    }
  }, [filter]);

  useEffect(() => {
    if (!checkIns.loading && checkIns.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_LIST_LOADING,
      });
      dispatch(listCheckIns());
    }
    if (!rigs.loading && rigs.data.length === 0) {
      dispatch({
        type: REDUX_ACTIONS.RIGS_LIST_LOADING,
      });
      dispatch(listRigs());
    }
  }, []);

  const getMapPin = () => {
    const pinColor = 'checkInsIcon';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
      })
    );
  };

  const getMapPin2 = () => {
    const pinColor = 'rigIcon';
    const pinIcon = pinColor ? pins[pinColor] : pins.default;

    return (
      pinIcon &&
      new Icon({
        iconUrl: pinIcon,
        iconSize: iconPinSize,
        popupAnchor: [0, -5],
        className: classes.rigIconPopup,
      })
    );
  };

  const getPinColor = (status) => {
    switch (status) {
      default:
        return 'red';
    }
  };

  const getGoogleMapPin = (status) => {
    const pinColor = getPinColor(status);
    const pinIcon = pinColor ? pins[pinColor] : pins.default;
    return {
      url: pinIcon,
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(iconPinSize[0], iconPinSize[1]),
      // eslint-disable-next-line no-undef
      size: new google.maps.Size(iconPinSize[0] + 10, iconPinSize[1] + 10)
    };
  };

  const handlePagination = (page) => {
    if (page < checkIns.currentPage) {
      dispatch(listCheckIns({ page: checkIns.currentPage - 1, limit: 100 }));
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_SET_CURRENT_PAGE,
        payload: checkIns.currentPage - 1
      });
    } else {
      dispatch(listCheckIns({ page: checkIns.currentPage + 1, limit: 100 }));
      dispatch({
        type: REDUX_ACTIONS.CHECKINS_SET_CURRENT_PAGE,
        payload: page + 1
      });
    }
    apiRef.current.setFilterModel({
      items: filter
    });
    apiRef.current.scrollToIndexes({
      rowIndex: 0,
      colIndex: 0,
    });
  };

  const handleMarkerClick = (data) => {
    const row = checkIns?.data.find((row) => row.grid_id === data.grid_id);
    if (row) {
      setRowId(row);
      const rowIndex = checkIns.data.findIndex((r) => r.grid_id === data.grid_id);
      if (rowIndex !== -1) {
        apiRef.current.scrollToIndexes({
          rowIndex,
          colIndex: 0,
        });
      }
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
      <Map
        showRigsToggleButton={true}
        data={checkIns}
        mapType={mapType}
        mapData={checkIns.data}
        markerData={checkIns.data}
        latitudeField={'lat'}
        longitudeField={'long'}
        mapPinField={'lastUpdate'}
        popUpLabel={['BinNum', 'UserEmail', 'comment']}
        excludePopupLabel={true}
        mapData2={rigs.marker_data}
        markerData2={rigs.marker_data}
        latitudeField2={'RigLatitudeWGS84'}
        longitudeField2={'RigLongitudeWGS84'}
        secondaryPopup={'RigName_Number'}
        getGoogleMapPin={getGoogleMapPin}
        getMapPin={getMapPin}
        getMapPin2={getMapPin2}
        gmapRowId={gmapRowId}
        rowId={rowId}
        referenceKey={'grid_id'}
        setIconPinSize={setIconPinSize}
        onMarkerClick={handleMarkerClick}
        showRigPinsByDefault={true}
      />
      </Grid>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={checkIns.data}
          columns={masterColumn()}
          onCol
          loading={checkIns.loading}
          getRowId={(row) => row.grid_id}
          autoPageSize
          autoHeight={false}
          pagination={false}
          hideFooter
          serverExport={() => dispatch(exportCheckIns())}
          onRowClick={({ row: well }) => {
            if (mapType === MAP_TYPES.GOOGLEMAP) {
              setGmapRowId(well._id);
            } else {
              setRowId(well);
            }
          }}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            '.MuiDataGrid-cell': {
              fontSize: 'medium',
              fontWeight: 'bold !important'
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              export: {
                enabled: true,
                options: true,
                serverExportMode: true
              },
            },
          }}
          filterMode="server"
          onFilterModelChange={(model) => {
            setFilter(model.items);
            const items = model.items.map(filter => {
              if (filter.columnField === 'lastUpdate' || filter.columnField === 'division') {
                return filter;
              } else if (filter.columnField === 'ShipToNum' || filter.columnField === 'CustNum' || filter.columnField === 'lat' || filter.columnField === 'long') {
                return { ...filter, columnField: 'details.' + filter.columnField, value: Number(filter.value) };
              } else {
                return { ...filter, columnField: 'details.' + filter.columnField };
              }
            });
            const filterModels = { ...model, items };
            dispatch({
              type: REDUX_ACTIONS.CHECKINS_SET_FILTER,
              payload: filterModels.items.length ? filterModels : {}
            });
          }}
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            const sort = sortModel.reduce((result, item) => {
              if (item.field === 'lastUpdate') {
                result[item.field] = item.sort;
              } else {
                result['details.' + item.field] = item.sort;
              }
              return result;
            }, {});
            dispatch({
              type: REDUX_ACTIONS.CHECKINS_SET_SORT,
              payload: sort
            });
            dispatch({
              type: REDUX_ACTIONS.CHECKINS_SET_CURRENT_PAGE,
              payload: 1
            });
            dispatch(listCheckIns());
          }}
          getRowClassName={(params) => (params.id === rowId?.grid_id ? classes.highlightRow : '')}
          />
        <CustomPagination
          currentPage={checkIns.currentPage - 1}
          limit={checkIns.limit}
          totalCount={checkIns.totalCount}
          handlePagination={handlePagination}
          loading={checkIns.loading}
        />
      </Grid>
    </Grid>
  );
};

CheckIns.propTypes = {
  row: PropTypes.object,
};

export default CheckIns;
