import { makeStyles } from '@mui/styles';

const styles = makeStyles(({ spacing, breakpoints }) => {
  return {
    marketSubtitle: {
      marginRight: '10px',
      [breakpoints.down(500)]: {
        fontSize: '30px',
      }
    },
    marketSubtitleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '50px',
      [breakpoints.down(500)]: {
        marginBottom: '30px'
      }
    },
    carouselContainerGridView: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill,minmax(auto, auto))',
      gridAutoFlow: 'column',
      gridAutoColumns: 'minmax(auto, auto)',
      overflowX: 'hidden',
      gap: spacing(4),
      position: 'relative',
      minHeight: '462px',
      [breakpoints.down(1240)]: {
        overflowX: 'scroll',
      },
      [breakpoints.down(500)]: {
        overflowX: 'scroll',
        gap: spacing(2),
        minHeight: '370px',
        paddingRight: '30px',
        paddingBottom: '10px',
        '&::-webkit-scrollbar': {
          visibility: 'hidden'
        },
      }
    },
    navIcons: {
      position: 'absolute',
      top: '55%',
      background: 'rgba(0,0,0,0.2)',
      maxWidth: '50px',
      maxHeight: '50px',
      [breakpoints.down(500)]: {
        display: 'none'
      }
    },
    leftIcon: {
      left: -55,
      [breakpoints.down(1240)]: {
        left: 0
      }
    },
    rightIcon: {
      right: -55,
      [breakpoints.down(1240)]: {
        right: 0
      }
    },
    featuredWrapper: {
      position: 'relative',
      padding: '70px 0',
      maxWidth: '1140px',
      margin: '0 auto',
      textAlign: 'center',
      [breakpoints.down(500)]: {
        padding: '30px 0',
      }
    },
    icon: {
      color: '#000',
      fontSize: '40px',
      [breakpoints.down(500)]: {
        fontSize: '34px',
      }
    }
  };
});

export default styles;
