import React, { useRef, useState } from 'react';
import SyncMobileDevicesModal from '../ControlPanel/SyncMobileDevices/SyncMobileDevicesModal/index';
import { Box, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import Premium from '../../../assets/images/PRO logo PMS green only.svg';
import styles from './styles';
import { CancelPresentationRounded, FileOpen } from '@mui/icons-material';
import PropTypes from 'prop-types';

function FileUploadDialog({ open, handleClose, isLoading, handleUpload, showDateTimeRange, showCta2 }) {
  const classes = styles();

  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState(null);
  const dropAreaRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'text/csv') {
      setSelectedFile(file);
      setError(null);
    } else {
      setSelectedFile(null);
      setError('Only .csv files are allowed');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'text/csv') {
      setSelectedFile(file);
      setError(null);
    } else {
      setSelectedFile(null);
      setError('Only .csv files are allowed');
    }
    e.dataTransfer.clearData();
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = () => {
    if (!selectedFile) {
      setError('Select a csv file to upload');
      return;
    }
    handleUpload(selectedFile);
  };

  return (
    <div>
      <SyncMobileDevicesModal
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        cta2Label={'Upload'}
        cta2Action={handleFileUpload}
        showCta2={showCta2}
        showDateTimeRange={showDateTimeRange}
        cta2Disabled={isLoading || !selectedFile}
      >
        <Grid container justifyContent='end' alignItems={'center'} style={{ marginTop: '-5%', marginLeft: '4%' }}>
          <IconButton onClick={() => {
            handleClose();
          }}>
            <CancelPresentationRounded fontSize={'large'}/>
          </IconButton>
        </Grid>
        <CardMedia
          component="img"
          image={Premium}
          className={classes.logo}
        />
        <Typography variant="h4" gutterBottom component="div" style={{ textAlign: 'center' }}>
          {isLoading ? 'Uploading...' : 'Add or select media'}
        </Typography>
        <Box
          className={isDragging ? classes.dragDrop : classes.dropArea}
          ref={dropAreaRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="fileInput"
            ref={fileInputRef}
          />
          <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
            Drop files here to upload them or press button
            <br />
            <br />
            <IconButton
              edge="end"
              sx={{ marginRight: '1%' }}
              onClick={handleIconClick}
            >
              <FileOpen />
           </IconButton>
          </label>
        </Box>
        {error && <Box className={classes.errorMessage}>{error}</Box>}
        {selectedFile && <Box className={classes.selectedFileMessage}>Selected file: {selectedFile.name}</Box>}
        <p className={classes.fileDescription}>
          Allowed file upload: <strong>one file only</strong>
          <br />
          File must be less than <strong>2 MB</strong>
          <br />
          Allowed file types: <strong>csv</strong>
        </p>
      </SyncMobileDevicesModal>
    </div>
  );
}

FileUploadDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
  handleUpload: PropTypes.func,
  showDateTimeRange: PropTypes.bool,
  showCta2: PropTypes.bool,
};

FileUploadDialog.defaultProps = {
  open: false,
  showDateTimeRange: false,
  showCta2: true,
  isLoading: false
};

export default FileUploadDialog;
