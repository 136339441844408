import fetch from '../../api/fetch';
import { REDUX_ACTIONS } from '../../shared/config/constants';
import { COMPANY_TYPE, USER_TYPE } from '../../utils/constants';
import { parseError } from '../../utils/fetchErrorParser';
import store from '../store';
import { enqueueSnackbar } from './snackbar';

export const listCompanies = () => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.COMPANIES_LIST_LOADING,
  });

  const body = { collectionName: 'Companies' };

  return fetch
    .post('companies/companies', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_LIST_SUCCESS,
        payload: {
          companies: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);

      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const addNewCompanyRow = () => (dispatch) => {
  const { companies, currentUser } = store.getState();
  const newCompany = [...companies.data];
  const nextId = newCompany.length - 1;

  function generateRandom() {
    const length = 24;
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const newRow = {
    name: '',
    legalName: '',
    type: currentUser.type === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN ? COMPANY_TYPE.MPD_CUSTOMER : '',
    country: '',
    state: '',
    phone: '',
    address1: '',
    postalCode: '',
    grid_id: nextId + 1,
    _id: generateRandom(),
    isNewRow: true,
  };
  newCompany.unshift(newRow);

  dispatch({
    type: REDUX_ACTIONS.COMPANIES_LIST_SUCCESS,
    payload: {
      companies: newCompany,
      paginatedElements: newCompany.length,
    },
  });
};

export const listSellersCustomers = (user) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.COMPANIES_LIST_LOADING,
  });

  const body = { user };

  return fetch
    .post('companies/sellers/customersList', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_LIST_SUCCESS,
        payload: {
          companies: data.values,
          paginatedElements: data.values.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const createCompany = (body) => (dispatch) => {
  return fetch
    .post('companies/company-create', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Company Details Created Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      dispatch(listCompanies());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const updateCompany = (body) => (dispatch) => {
  return fetch
    .put('companies', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .then(() => {
      dispatch(
        enqueueSnackbar(
          'Company Details Updated Successfully.',
          'success',
          new Date().getTime() + Math.random()
        )
      );
      dispatch(listCompanies());
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const getCustomersSellers = (user) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.COMPANIES_LIST_LOADING,
  });

  const body = { companyId: user.companyId };

  return fetch
    .post('companies/customerSellers', body)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_LIST_SUCCESS,
        payload: {
          companies: data.data,
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.COMPANIES_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};

export const listSellersUsers = (user) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.USERS_LIST_LOADING,
  });

  const body = { user };

  return fetch
    .post('companies/sellers/usersList', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      dispatch({
        type: REDUX_ACTIONS.USERS_LIST_SUCCESS,
        payload: {
          users: data.data.map(user => ({
            ...user,
            orders: [...user.orders].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
            quotes: [...user.quotes].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          })),
          paginatedElements: data.data.length,
        },
      });
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      dispatch({
        type: REDUX_ACTIONS.USERS_LIST_ERROR,
        payload: { ...error, message: errorMessage },
      });
    });
};
